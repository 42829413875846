.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #024051;
}

h1.ant-typography,
.ant-typography h1 {
    margin-bottom: 0px;
    color: #024051;
    font-weight: 600;
    line-height: 1.23;
    padding-left: 100px;
}

.logo {
    border: 0px solid black;
    margin-top: 10px;
    width: 480px;
    height: 58px;
    display: block;
}

.logo img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    aspect-ratio: auto 480 / 58;
}

.site-layout .site-layout-background {
    background: #fff;
}

.ant-layout-header {
    height: 80px;
    padding: 0 20px;
    line-height: 64px;
    background: #ffffff;
    margin-top: 15px;
}

.ant-layout-sider-children {
    height: 100%;
    margin-top: 15px;
    padding-left: 16px;
}

.ant-layout-sider-has-trigger {
    padding-bottom: 48px;
    padding-top: 48px;
}

.ant-layout-sider-children {
    height: 100%;
    margin-top: 15px;
    padding-left: 16px;
    background: #024051;
}


@media only screen and (min-width: 1130px) {
    .content {
        position: relative;
        max-width: 1418px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.header {
    height: 80px;
    margin-top: 10px;
    display: flex;
}

.header .logo {
    display: flex;
    flex-wrap: wrap;
}

.header-logo {
    height: 50px;
    background: url(../images/bmw-group-logos.png) no-repeat 12px 0;
    width: 245px;
    display: block;
    margin-top: 7px;
}

.header-title {
    color: #464343;
    font-weight: bold;
    font-family: 'BMW Group Condensed';
    text-transform: uppercase;
    line-height: 38px;
    margin: 15px 100px 10px 15px;
    font-size: 30px;
    padding: 0 0 0 0;
}

h2 {
    color: #464343;
    font-size: 45px;
    font-family: BMWGroup_Cond;
    font-weight: bold;
    margin: 0 0 10px 0;
    line-height: normal;
}

#mainContent {
    width: 100%;
    height: auto;
    max-width: 1418px;
    background: url(../images/background.png) no-repeat;
}

@media only screen and (min-width: 1130px) {
    .text-area {
        top: 90px;
        left: 30px;
        width: 500px;
        margin-top: 0;
        padding: 0 0;
    }
}

@media only screen and (min-width: 1130px) {
    .text-area,
    .link-area {
        position: absolute;
    }
}

.log-in-header {
    color: #464343;
    font-size: 45px;
    font-family: 'BMW Group';
    font-weight: bold;
    margin: 0 0 10px 0;
    line-height: normal;
}

@media only screen and (min-width: 1130px) {
    .login {
        position: absolute;
    }
}

@media only screen and (min-width: 1130px) {
    .login {
        top: 90px;
        left: auto;
        right: 30px;
        margin-bottom: 0;
        z-index: 100;
        width: 350px;
    }
}

@media only screen and (min-width: 1130px) {
    .loginHeaderBox {
        background: rgba(255, 255, 255, 0.5);
    }
}

.loginHeaderBox {
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.1);
    font-size: 25px;
    font-weight: bold;
    font-family: 'BMW Group';
    margin: 0 0 5px 0;
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    color: #464343;
}

@media only screen and (min-width: 1130px) {
    .loginContainerPasswordAuthentication,
    .loginContainerSelectAuthentication,
    .loginContainerSecurIDAuthentication,
    .loginContainerIWAAuthentication {
        background: rgba(255, 255, 255, 0.5);
    }
}

.loginContainerIWAAuthentication {
    position: relative;
    top: 0;
    left: 0;
    border: none;
    margin-top: 5px;
    max-width: none;
}

.loginContainerPasswordAuthentication,
.loginContainerSelectAuthentication,
.loginContainerSecurIDAuthentication,
.loginContainerIWAAuthentication {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
}


.cell--bordered{
    border-style: solid;
    border-width: 1px;
    padding: 0px 2px 0px 4px;    
}


.form--table td{
    vertical-align: top;
}

.menu-item-margin {
    margin-left:15px;
}

.menu-item-capitalize {
    text-transform: capitalize;
}
.menu-item-to-uppercase {
    text-transform: uppercase;
}
.ant-pro-global-header-header-actions-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-block: 0;
    padding-inline: 2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    padding-bottom: 20px;
}

.ant-pro-global-header-header-actions-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-block: 0;
    padding-inline: 2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    padding-bottom: 20px;
}

.ant-pro-global-header-header-actions-item:hover{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-block: 0;
    padding-inline: 2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    padding-bottom: 20px;
}



.my-prefix-layout .ant-layout-header.my-prefix-layout-header {
    border-block-end: 5px solid #024051;
}

.edp-link {
    color: rgb(26,13, 171);
}

.edp-link:hover {
    text-decoration: underline;
}

.edp-link:visited {
    color: purple;
}

.my-prefix-layout .my-prefix-layout-content {
    padding-block: 0;
    padding-inline: 0;
}
