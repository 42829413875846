.currencySelect {
    cursor:pointer !important;
}

.roundedBorder {
    border:1px solid #d9d9d9;
    border-radius: 2px;
    padding-left:5px;
}

.headerDetailsContainer {
    display: grid;
    grid-column-gap: 10px;
    margin-left:15px;
    grid-template-columns: 130px 140px 140px 140px 140px 140px;
    grid-template-rows: 40px 40px 50px;
    overflow-x: auto;
}

.headerDetailsContainer[class~=failed-validation] {
    grid-template-rows: 40px 180px 50px;
}

.mandatoryField {
    color: red;
}

.headerDetailsContainer [class~=ant-col-sm-20],[class~=ant-col]{
    max-width:95%;
}

.headerNotesFirstRowContainer {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 150px 160px 150px 320px;
    grid-template-rows: 40px 90px;
    overflow-x: auto;
}

.headerNotesSecondRowContainer {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 150px 160px 150px;
    grid-template-rows: 40px 40px;
    overflow-x: auto;
}

.headerNotesFirstRowContainer [class~=ant-col-sm-20]{
    max-width:95%;
}

.headerNotesSecondRowContainer [class~=ant-col-sm-20]{
    max-width:95%;
}

.headerNotesWrapper [class~=ant-card-body] {
    padding:0px
}

.headerNotesWrapper::before {
    padding:0px;
}

.hideNoteItem {
    display:none;
}

.lineItemOverflowContainer {
    border:1px solid grey;
    overflow-x: auto;
    padding-bottom:20px;
}

.lineItemsContainer {
    padding:7px 5px;
    width:3450px;
    font-size: 14px;
}

.lineColumn1{display:inline-block;width:60px}
.lineColumn2{display:inline-block;width:100px;text-align: center}
.lineColumn2_display{display:inline-block;width:100px;text-align: center;font-family: "BMW Group"; font-variant: "tabular-nums";font-size: 12px;}
.lineColumn3{display:inline-block;width:110px}
.lineColumn4{display:inline-block;width:120px }
.lineColumn5{display:inline-block;width:140px }
.lineColumn6{display:inline-block;width:150px}
.lineColumn7{display:inline-block;width:105px}
.lineColumn8{display:inline-block;width:100px }
.lineColumn9{display:inline-block;width:100px }
.lineColumn10{display:inline-block;width:120px }
.lineColumn11{display:inline-block;width:100px }
.lineColumn12{display:inline-block;width:100px }
.lineColumn13{display:inline-block;width:100px }
.lineColumn14{display:inline-block;width:100px }
.lineColumn15{display:inline-block;width:150px }
.lineColumn16{display:inline-block;width:100px }
.lineColumn17{display:inline-block;width:100px }
.lineColumn18{display:inline-block;width:200px }
.lineColumn19{display:inline-block;width:130px }
.lineColumn20{display:inline-block;width:130px }
.lineColumn21{display:inline-block;width:150px}

.lineItemsContainer [class~=ant-input]{
    font-size: 10px;
}

.lineItemBottomButtons {
    width:100%;
    margin-top:10px;
    display: flex;
    justify-content: flex-end;
}

.lineItemButtonLinks {
   color:rgba(0, 0, 0, 0.5)
}

.lineItemsContainer input[class~=ant-input]{
    width:100%;
    background-color: white;
}

.lineItemsContainer [class~=ant-input-suffix]{
}

.lineItemsContainer [class~=ant-select-selection-item] {
    font-size: 10px;
}

.lineItemsContainer [class~=ant-select-selection-placeholder] {
    font-size: 10px;
}

.lineItemData {

}

.lineItemData .lineItemsContainer {
    border-top:1px solid black;
}
.lineItemData .lineItemsContainer:nth-child(even){
    background-color: #e8e8e8;
}

.lineItemData .lineItemsContainer [class~=ant-col-sm-20] {
    max-width:95%;
}

.lineItemLastRowBorderBottom {
    border-bottom:1px solid black;
}

.hideLineItem {
    display:none;
}

.lineItemColumn {

}

.lineItemHeading {

}

.lineItemData [class~=ant-input-disabled] {
    color: black !important;
    background:white !important;
}

.lineItemData [class~=disabledNumeric] {
    text-align: right;
    height: 25px;
    margin-top:3px;
}

.lineItemData [class~=ant-input-number-input] {
    text-align: right;
    height: 25px;
}

.lineItemData [class~=ant-select-selector] {
    height: 25px !important;
}

.lineItemData [class~=ant-input-number-input-wrap] {
    border-bottom: solid #666666 1px;

}

.lineItemTotalsGrid {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 150px 150px 150px 150px 150px;
    grid-template-rows: 40px 40px;
}

.lineItemTotalsData [disabled]{
    color: black !important;
    background:white !important;
}

.lineItemTotalsData [class~=ant-input-number-input] {
    text-align: right;
}

.lineTotalCollapsableCard [class~=ant-card-body] {
    padding:0px
}

.lineItemTotalsLocalCurrency {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 100px 100px 150px 100px 100px 100px 100px;
    grid-template-rows: 40px;
}

.lineItemTotalTaxSummary {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 150px 150px 150px 150px;
    grid-template-rows: 40px;
}

.attachmentContainer {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 400px 150px;
    grid-template-rows: 40px;
}

.draftDocumentFirstRow {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 200px 200px 300px;
}

.draftDocumenSecondRow {
    display: grid;
    grid-column-gap: 2px;
    grid-template-columns: 500px 315px;
}



